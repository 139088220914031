@media print {
    header, footer, aside, form, .sideBar{
        display: none !important;
    }

    h2 {
        font-size: 14px;
    }
    main {
        padding: 24px !important;
    }
    .block_details_order {
        margin-top: 20px !important;
    }
    .block_header_order {
        margin-bottom: 24px !important;
    }
}

.MuiTableContainer-root .MuiTable-root .MuiTableBody-root tr td:only-child {
    white-space: nowrap;
    display: flex;
    justify-content: center;
}

p.MuiTypography-root.MuiTypography-body1 {
    max-width: none !important;
}
